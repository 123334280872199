import React, { useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'

import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { ButtonType } from '../../../../resuable/button'
import { colors } from '../../../../../utils/styles/styles'
import { countryList, countryListWithFlag } from '../../../../../utils/helpers/countries'

const StyledSeoReportCta = styled.div`
  width: 100%;
  .container__image {
    height: auto;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    .seo-image {
      width: 60%;
    }
  }
  div.container__input {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      margin-bottom: 4px;
      text-align: center;
    }
    span.subtitle {
      margin-bottom: 16px;
      text-align: center;
    }
    div.container__input-search {
      position: relative;
      width: 99%;
      max-width: 600px;
      padding: 0px 0px 20px;
      display: flex;
      .country-selector-container {
        width: 30%;
        .country-select__control {
          height: 48px;
          border-radius: 30px;
          font-size: 16px;
          box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
          .country-select__value-container {}
          .country-select__indicators {}
        }
        .country-select__menu {
          .country-select__menu-list {
            .country-select__option {
              color: #011;
            }
          }
        }
      }
      input.url {
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        margin-left: 10px;
        padding: 0px 150px 0px 20px;
        border: 1px solid #ddd;
        border-radius: 30px;
        color: ${colors.darkGray};
        box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
        font-size: 16px;
        &:focus {
          outline: none;
        }
      }
      button.seo-audit {
        position: absolute;
        border: 0px;
        top: 0px;
        right: 0px;
        height: 48px;
        padding: 0 24px;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 0 20px;
    .container__image {
      width: 100%;
      .seo-image {
        width: 100%;
      }
    }
    div.container__input {
      div.container__input-search {
        flex-direction: column;
        align-items: center;
        > * {
          padding-bottom: 10px;
        }
        .country-selector-container {
          width: 50%;
        }
        input.url {
          width: 90%;
          margin: 0px;
          padding: 0 12px;
        }
        button.seo-audit {
          position: static;
          margin-top: 10px;
        }
      }
    }
  }
`

const SeoReportCta = (props) => {
  const data = useStaticQuery(graphql`
    query seoMockupImage {
      file(relativePath: {eq: "seo-report/seo-screen.png"}) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const [url, setUrl] = useState('');
  const [country, setCountry] = useState('US');
  const handleUrlChange = (e) => {
    setUrl(e.target.value)
  }
  const handleCountryChange = (inputValue) => {
    setCountry(inputValue.value)
  }
  const handleSubmit = () => {
    if(url !== '') {
      window.open(`https://www.leadmirror.com/tool/seo-report/g?country=${country}&utm_campaign=&url=${url}`);
    }
  }
  return (
    <StyledSeoReportCta>
      <div className="container__image">
        <Img
          fluid={data.file.childImageSharp.fluid}
          className="seo-image"
        />
      </div>
      <div className="container__input">
        <h3>Do Search engines love your page?</h3>
        <span className="subtitle">400+ metrics, 150+ insights for comprehensive webpage SEO analysis</span>
        <div className="container__input-search">
          <Select
            options={countryList}
            placeholder="Country"
            isSearchable={true}
            onChange={handleCountryChange}
            className="country-selector-container"
            classNamePrefix="country-select"
          />
          <input
            className="url"
            type="text"
            placeholder="Enter your website url"
            value={url}
            onChange={handleUrlChange}
          />
          <ButtonType.Base
            onClick={handleSubmit}
            className="seo-audit"
            id="home__seo-reportsubmit"
          >
            Submit
          </ButtonType.Base>
        </div>
      </div>
    </StyledSeoReportCta>
  )
}

SeoReportCta.defaultProps = {

}

export default SeoReportCta