import React, { useState, useContext } from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

import styled, { ThemeContext } from "styled-components"
import { colors } from "../../../../utils/styles/styles"

import Section from "../../../resuable/section/section"
import ModalAdapter from "../../../resuable/modal/ModalAdapter"

import "./home-epic-section.scss"
import { ButtonType } from "../../../resuable/button"
import { getSourceLink } from "../../../../utils/tracker/links"

const HomeEpicSectionInnerContainer = styled.div`
  height: 420px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  a {
    text-decoration: none;
  }
  div.container__video,
  div.container__text {
    flex-basis: 40%;
    margin: 0px 20px;
  }
  div.container__video {
    cursor: pointer;
    .img-epic {
    }
    video {
      height: 22.5rem;
      width: 40rem;
    }
  }
  div.container__text {
    h5 {
      font-size: 3rem;
      color: ${props => props.theme.headingColor};
      span {
        font: inherit;
        color: ${colors.priDark};
      }
    }
    p {
      font-size: 16px;
      color: ${colors.lightGray};
    }
    a {
      display: inline-block;
    }
    span.nocc {
      margin-top: 6px;
      margin-left: 20px;
      font-size: 8px;
      color: ${colors.gray};
    }
  }
  @media (max-width: 768px) {
    height: auto;
    padding: 4rem 1rem 2rem;
    flex-direction: column;
  }
`

const HomeEpicSection = () => {
  const data = useStaticQuery(graphql`
    query epicImage {
      file(relativePath: { eq: "home/video2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const theme = useContext(ThemeContext)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }
  const afterOpenModal = () => {
    console.log("Closed")
  }
  return (
    <Section fullwidth={false} justifyContent="center" id="epic">
      <HomeEpicSectionInnerContainer>
        <div className="container__video" onClick={openModal}>
          <Img fluid={data.file.childImageSharp.fluid} className="img-epic" />
        </div>
        <div className="container__text">
          <h5>
            Deploy A2i marketing and yield <span>80%</span> results with{" "}
            <span>20%</span> effort.
          </h5>
          <p>
            Artificial 2 Human Intelligence ( A2i ) delivers growth oriented
            data insights & analysis with smartly crafted machine learning
            framework. A2i allows you to unlock your in-depth marketing
            potential & opportunities.
          </p>
          {/* <a
            href={`https://app.leadmirror.com/#/signup/wizard/1?initLandingPage=${getSourceLink()}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonType.Base variant="secondary" id="home__epic-expdemo">
              Experience Live Demo
            </ButtonType.Base>
          </a> */}
          <Link to="/demo">
            <ButtonType.Ghost className="demo" id="home__epic-expdemo">
              Get personalised Demo
            </ButtonType.Ghost>
          </Link>
          {/* <Link to="/pricing">
            <ButtonType.Base variant="secondary" id="home__epic-expdemo">
              Start For Free Now!
            </ButtonType.Base>
          </Link> */}
        </div>
        <ModalAdapter
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          className="homeepichero"
        >
          <iframe
            width="100%"
            height="400px"
            src={`https://www.youtube.com/embed/K6-wjp10SuI?autoplay=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </ModalAdapter>
      </HomeEpicSectionInnerContainer>
    </Section>
  )
}

export default HomeEpicSection
