import React, { useState } from "react"
import styled from "styled-components"
import BlogCardImageBack from "../../../resuable/blog/blog-card/blog-card-image-back"
import Section from "../../../resuable/section/section"
import Row from "../../../resuable/row/row"
import { Link, useStaticQuery, graphql } from "gatsby"
import ItemsCarousel from "react-items-carousel"

import { colors } from "../../../../utils/styles/styles"
import useWindowSize from "../../../hooks/useWindowSize"

const StyledHomeBlogSection = styled.div`
  width: 100%;
  padding: 80px 20px 20px;
  div.container__header {
    h2 {
      text-align: center;
    }
    h3 {
      color: ${colors.lightGray};
      text-align: center;
      font-size: 18px;
      font-weight: 400;
    }
  }
  div.container__body {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    .cards {
      width: 100%;
      .wrapper {
        width: 100%;
      }
      .itemsWrapper {
        overflow-y: hidden;
      }
      .rightChevronWrapper,
      .leftChevronWrapper {
        button {
          cursor: pointer;
          height: 30px;
          width: 30px;
          background: #fff;
          color: #232232;
          border-radius: 50%;
          font-weight: bold;
          font-size: 16px;
        }
      }
      /* display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: space-between; */
    }
    .seemore {
      padding: 20px 0px;
      justify-content: flex-end;
      button {
        cursor: pointer;
        background: transparent;
        color: ${colors.lightGray};
        font-size: 14px;
      }
    }
  }
  @media (max-width: 768px) {
    div.container__body {
      width: auto;
      .cards {
        
      }
    }
  }
`

const HomeBlogSection = props => {
  const data = useStaticQuery(graphql`
    query allPostsforhomepage {
      allWordpressPost(limit: 8, sort: {fields: date, order: DESC}) {
        edges {
          node {
            id
            title
            excerpt
            slug
            featured_media {
              source_url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 70) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const { edges: posts } = data.allWordpressPost
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const chevronWidth = 40
  const { width } = useWindowSize()
  return (
    <Section fullwidth={true} padding="0px">
      <StyledHomeBlogSection>
        <div className="container__header">
          <h2>Latest from A2i World</h2>
          <h3>
            Your personalized one stop place for Blogs, Articles, Links to
            amazing resources
          </h3>
        </div>
        <div className="container__body">
          <Row className="cards">
            <ItemsCarousel
              classes={{
                wrapper: "wrapper",
                itemsWrapper: "itemsWrapper",
                itemsInnerWrapper: "itemsInnerWrapper",
                itemWrapper: "itemWrapper",
                rightChevronWrapper: "rightChevronWrapper",
                leftChevronWrapper: "leftChevronWrapper",
              }}
              infiniteLoop={true}
              gutter={20}
              firstAndLastGutter={true}
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              numberOfCards={width >= 768 ? 4 : 1}
              slidesToScroll={1}
              disableSwipe={false}
              showSlither={true}
              leftChevron={<button>{"<"}</button>}
              rightChevron={<button>{">"}</button>}
              outsideChevron={false}
              chevronWidth={chevronWidth}
            >
              {posts !== null
                ? posts.map(post => {
                    const {
                      id,
                      title,
                      excerpt,
                      slug,
                      featured_media,
                    } = post.node
                    return (
                      <BlogCardImageBack
                        key={id}
                        title={title}
                        excerpt={excerpt}
                        slug={slug}
                        featured_media={featured_media}
                      />
                    )
                  })
                : null}
            </ItemsCarousel>
          </Row>
          <Row className="seemore">
            <Link to="/a2i-world/">
              <button>See more...</button>
            </Link>
          </Row>
        </div>
      </StyledHomeBlogSection>
    </Section>
  )
}

HomeBlogSection.defaultProps = {}

export default HomeBlogSection
