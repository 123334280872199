import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import styled, { ThemeContext } from 'styled-components'
import Row from '../../../resuable/row/row'
import Column from '../../../resuable/column/column'

import Section from '../../../resuable/section/section'

const StyledHomeUsecaseSection = styled.div`
  width: 100%;
  div.row__title {
    width: 100%;
    padding: 20px 0px 30px; 
    h4 {
      font-size: 30px;
      text-align: center;
    }
  }
  div.row__usecases {
    width: 100%;
    div.column__usecase {
      display: flex;
      flex-direction: column;
      align-items: center;
      div.inner {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        svg, img {
          margin-bottom: 20px;
          display: inline-block;
        }
        span.title {
          font-size: 24px;
          font-weight: bold;
          color: ${props => props.theme.headingColor};
        }
      }
    }
  }
  @media (max-width: 768px) {
    padding: 10px 20px;
    div.row__title {
      padding: 20px 0px;
      h4 {
        font-size: 24px;
      }
    }
    div.row__usecases {
      div.column__usecase {
        align-items: center;
        div.inner {
          width: 80%;
          img {
            height: 120px;
          }
        }
      }
    }
  }
`

const HomeUsecaseSection = () => {
  const theme = useContext(ThemeContext);
  const data = useStaticQuery(graphql`
    query homeUsecases {
      wordpressPage(slug: {eq: "home"}) {
        acf {
          use_cases {
            section_title
            section_content {
              title
              points {
                point
              }
              image {
                url {
                  source_url
                }
              }
            }
          }
        }
      }
    }  
  `);
  const { section_title, section_content } = data.wordpressPage.acf.use_cases;
  console.log(section_content);
  return (
    <Section
      padding="0px"
    >
      <StyledHomeUsecaseSection>
        <Row className="row__title">
          <h4>Your Progress is our success Story</h4>
        </Row>
        <Row className="row__usecases">
          {
            section_content.map((usecase, index) => {
              return (
                <Column
                  key={index}
                  className="column__usecase"
                >
                  <div className="inner">
                    <img src={usecase.image.url.source_url} alt="Icons for usecase" />
                    <span className="title">{usecase.title}</span>
                    <ul>
                      {
                        usecase.points.map((point, index) => {
                          return (
                            <li key={index}>{point.point}</li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </Column>
              )
            })
          }
        </Row>
      </StyledHomeUsecaseSection>
    </Section>
  )
}

HomeUsecaseSection.defaultProps = {

}

export default HomeUsecaseSection