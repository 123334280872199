import React, { Component } from 'react'
import styled from 'styled-components'
import BusinessCta from './business-cta/business-cta'
import Section from '../../../resuable/section/section'
import SeoReportCta from './seo-report-cta/seo-report-cta'

const HomeSeoSectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  background: transparent;
`

export class HomeSeoSection extends Component {
  render() {
    return (
      <Section
        fullwidth={false}
      >
        <HomeSeoSectionContainer>
          <BusinessCta />
          <SeoReportCta />
        </HomeSeoSectionContainer>
      </Section>
    )
  }
}

export default HomeSeoSection
