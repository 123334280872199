import React from 'react'
import styled from 'styled-components'
import Row from '../../../../resuable/row/row'
import Column from '../../../../resuable/column/column'
import { colors } from '../../../../../utils/styles/styles'
import { ButtonType } from '../../../../resuable/button'
import { Link } from 'gatsby'

const StyledBusinessCta = styled.div`
  width: 100%;
  .container__header {
    width: 100%;
    padding: 2.5rem 0;
    h4 {
      text-align: center;
      font-size: 36px;
    }
  }
  .container__targets {
    width: 100%;
    margin-bottom: 10rem;
    padding: 30px 10px;
    background: ${colors.priGradient};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    .column__businesscta {
      width: 100%;
      padding: 0px 12px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      h5 {
        margin: 4px 0px 6px;
        color: #fff;
      }
      span.caption {
        font-size: 12px;
        max-width: 180px;
        width: 100%;
        text-align: center;
        line-height: 120%;
        color: #F2F2F2;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 0 20px;
    .container__header {
      h4 {
        text-align: center;
        font-size: 24px;
      }
    }
    .column__businesscta { {
      padding: 20px 0px;
    }
  }
`

const BusinessCta = () => {
  return (
    <React.Fragment>
      <StyledBusinessCta>
        <Row className="container__header">
          <h4>We have a plan for every stage of your business</h4>
        </Row>
        <Row columns={4} gap="10px" className="container__targets">
          <Column className="column__businesscta">
            <h5>Startups</h5>
            <span className="caption">Kick starts your first web traffic.</span>
          </Column>
          <Column className="column__businesscta">
            <h5>Businesses</h5>
            <span className="caption">Build long-term strategies.</span>
          </Column>
          <Column className="column__businesscta">
            <h5>Agencies</h5>
            <span className="caption">Manage multiple clients at ease.</span>
          </Column>
          <Column className="column__businesscta">
            <Link to="/pricing">
              <ButtonType.Base variant="secondary" id="home__seo--pricing">
                See Pricing
              </ButtonType.Base>
            </Link>
          </Column>
        </Row>
      </StyledBusinessCta>
    </React.Fragment>
  )
}

export default BusinessCta