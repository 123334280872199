import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SnowStorm from 'react-snowstorm';

import Img from 'gatsby-image'
import BackPattern from '../../../../images/patterns/dark_mosaic/dark_mosaic/dark_mosaic.png'

import styled from 'styled-components'

import Section from '../../../resuable/section/section'
import { fonts, colors } from '../../../../utils/styles/styles'
import { ButtonType } from '../../../resuable/button'
import Arrow from '../../../../assets/button/arrow.inline.svg'

import NewHero from '../../../../assets/home/hero_v2.inline.svg'
import { getSourceLink } from '../../../../utils/tracker/links';

const HomeHeroInnerContainer = styled.div`
  height: 80vh;
  width: 100%;
  /* margin: 0px 0px 3rem; */
  /* padding: 2rem 0px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: transparent;
  /* background-image: url(${BackPattern}); */
  background-repeat: repeat;
  font-family: ${fonts.sec};
  a {
    text-decoration: none;
  }
  .container__video {
    height: 100%;
    width: 100%;
  }
  div.container__left {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    div.container__left-text {
      width: 100%;
      max-width: 400px;
      h1 {
        margin-bottom: 0.8rem;
        font-size: 4.8rem;
        background: ${colors.priGradient};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 150%;
      }
      span.subtitle {
        display: inline-block;
      }
      button {
        margin-top: 20px;
        animation: 3s glow infinite forwards;
      }
    }
  }
  div.container__right {
    position: relative;
    overflow: hidden;
    margin-left: 20px;
    svg {
      height: 540px;
      .blue_ring {
        animation: 3s blueRing linear infinite forwards;
      }
      .pink_ring {
        /* animation: 4s pinkRing linear infinite forwards; */
      }
    }
    .img__hero {
      position: absolute;
      left: 0px;
      width: 110%;
    }
    
  }
  @media (max-width: 1024px) {
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: 8rem 0rem 2rem;
    text-align: center;
    div.container__left {
      order: 2;
      flex: 100%;
      width: 100%;
      margin-top: 0px;
      div.container__left-text {
        width: 100%;
        max-width: none;
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          font-size: 36px;
        }
      }
    }
    div.container__right {
      order: 3;
      flex: 100%;
      height: auto;
      width: 100%;
      padding: 0px 0px;
      overflow: hidden;
      .img__hero {
        position: static;
        width: 120%;
      }
      svg {
        width: 100%;
        margin-top: 40px;
      }
    }
  }
  @keyframes glow {
    0% { box-shadow: 0px 0px 0px rgba(215, 173, 12, 0); }
    50% { box-shadow: 0px 0px 30px rgba(215, 173, 12, 0.65); }
    100% { box-shadow: 0px 0px 0px rgba(215, 173, 12, 0); }
  }
  @keyframes blueRing {
    0% { transform: translateX(-20px) }
    50% { transform: translateX(0px)  }
    100% { transform: translateX(-20px) }
  }
  @keyframes pinkRing {
    0% { transform: translateY(0px) }
    50% { transform: translateY(-2px)  }
    100% { transform: translateY(0px) }
  }
`
const HomeHero = ({
  title,
  subtitle
}) => {
  const data = useStaticQuery(graphql`
  query heroImage {
    file(relativePath: {eq: "home/mockup-min.png"}) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `);
  return (
    <Section
      height="auto"
      fullwidth={true}
      padding="0px"
    >
      <HomeHeroInnerContainer>
        <div className="container__left">
          <div className="container__left-text">
            <h1>{title}</h1>
            <span className="subtitle">{subtitle}</span>
            <a href={`https://app.leadmirror.com/#/signup/wizard/1?initLandingPage=${getSourceLink()}`} target="_blank" rel="noopener noreferrer">
              <ButtonType.Base variant="secondary" id="home__hero-cta">
                Start My Free Trial <Arrow />
              </ButtonType.Base>
            </a>
          </div>
        </div>
        <div className="container__right">
          {/* <Img fluid={data.file.childImageSharp.fluid} className="img__hero" /> */}
          <NewHero />
        </div>
      </HomeHeroInnerContainer>
    </Section>
  )
}

HomeHero.defaultProps = {
  title: 'Simplified Marketing Starts Today',
  subTitle: 'Reflecting data to build better strategies'
}


export default HomeHero
