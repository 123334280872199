import React from "react"
import Layout from "../components/resuable/layout/layout"

import SEO from "../components/resuable/seo/seo"

import HomeHero from "../components/page/home-components/home-hero/home-hero"
import HomeClientsSection from "../components/page/home-components/home-clients-section/home-clients-section"
import HomeEpicSection from "../components/page/home-components/home-epic-section/home-epic-section"
import HomeTestimonialSection from "../components/page/home-components/home-testimonial-section/home-testimonial-section"
import HomeFeaturesSection from "../components/page/home-components/home-features-section/home-features-section"
import HomeUsecaseSection from "../components/page/home-components/home-usecase-section/home-usecase-section"
import HomeSeoSection from "../components/page/home-components/home-seo-section/home-seo-section"
import Joinus from "../components/resuable/joinus/joinus"
import useWindowSize from "../components/hooks/useWindowSize"
import { graphql } from "gatsby"
import HomeHeroNoImage from "../components/page/home-components/home-hero/variants/home-hero-no-image"
import { FeatureSection } from "../components/page/home-components/home-features-section"
import HomeBlogSection from "../components/page/home-components/home-blog-section/home-blog-section"
import { EpicSection } from "../components/page/home-components/home-epic-section"

const IndexPage = ({ data }) => {
  const { title, yoast_meta, acf } = data.wordpressPage
  const { yoast_wpseo_title, yoast_wpseo_metadesc } = yoast_meta
  const size = useWindowSize()
  return (
    <Layout>
      <SEO title={yoast_wpseo_title} description={yoast_wpseo_metadesc} />
      {/* <HomeHero
        title={acf.hero_section.page_title}
        subtitle={acf.hero_section.page_subtitle}
      /> */}
      <HomeHeroNoImage />
      <HomeClientsSection />
      {/* <EpicSection.ExplainerVideo /> */}
      <FeatureSection.TabsHighlight />

      <HomeEpicSection />
      {/* <HomeFeaturesSection /> */}
      <HomeBlogSection />
      {/* <HomeSeoSection /> */}
      <HomeTestimonialSection />
      <Joinus />
    </Layout>
  )
}

export const query = graphql`
  query homePageMeta {
    wordpressPage(slug: { eq: "home" }) {
      title
      acf {
        hero_section {
          page_title
          page_subtitle
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`

export default IndexPage
